// import './wdyr'; // <--- first import

import React from "react";
import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

// amplify v6 - adminAPI SSO, CNEAT, echoar
import amplifyconfig from "./amplifyconfiguration.json";

import AppWithAuth from "./AppWithAuth";

import * as serviceWorker from "./serviceWorker";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [localRedirectSignIn, productionRedirectSignIn] =
//   awsExports.oauth.redirectSignIn?.split(",");

// const [localRedirectSignOut, productionRedirectSignOut] =
//   awsExports.oauth.redirectSignOut?.split(",");

const localRedirectSignIn = "https://localhost:3000/";
const productionRedirectSignIn = process.env.REACT_APP_PRODUCTION;

const localRedirectSignOut = "https://localhost:3000/";
const productionRedirectSignOut = process.env.REACT_APP_PRODUCTION;

const updatedAwsConfig = {
  ...amplifyconfig,
  /* oauth only for social providers
  oauth: {
    ...amplifyconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
  */
};

Amplify.configure(updatedAwsConfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

// if (prodEnvironment === true) {
//   const aws = awsExports;
//   Amplify.configure(aws);
//   console.log("AmplifyEnv prod", aws);
// } // if development, use updatedAwsConfig
// else {
//   const aws = updatedAwsConfig;
//   Amplify.configure(aws);
//   console.log("AmplifyEnv not prod", aws);
// }

render(
  <Router>
    <AppWithAuth />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
